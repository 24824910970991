import { faker } from "@faker-js/faker";
import { Factory } from "fishery";
import { Asset } from "../../modules/asset-library/types";
import { toISO8601 } from "../../utils/iso8601";
import { uuidv4 } from "../../utils/uuid";

const contentTypes = [
  "Poster",
  "FAQ",
  "Zoom Background",
  "Square Logo",
  "Landscape Logo",
  "Certificate",
  "Manager One-Page",
  "TV Display",
];

export const assetFactory = Factory.define<Asset>(() => ({
  account_id: uuidv4(),
  content_type: faker.helpers.arrayElement(contentTypes),
  created_at: toISO8601(faker.date.recent()),
  asset_id: uuidv4(),
  title: faker.word.words(faker.number.int({ min: 1, max: 10 })),
  thumbnail_url: "/placeholder.svg",
  updated_at: toISO8601(faker.date.recent()),
  deleted_at: null,
  project_id: faker.string.alphanumeric(16),
  width: faker.number.int(1000),
  height: faker.number.int(1000),
  units: "px",
}));
