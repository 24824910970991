import { FC, useCallback, useEffect, useState } from "react";
import { Editor, EditorConfig, EditorReadyHandler } from "./types";

export const DesignHuddleEditor: FC<{ onReady?: EditorReadyHandler } & EditorConfig> = ({
  onReady,
  project_id,
  ...config
}) => {
  const [initialized, setInitialized] = useState(false);
  const [editor, setEditor] = useState<Editor>();
  const editorRef = useCallback(
    (instance: HTMLDivElement | null) => {
      if (!editor && instance?.id) {
        DSHDLib.Editors.insert(
          instance.id,
          {
            project_id,
            ...config,
          },
          (err, e) => {
            if (err) {
              console.error(err);
            } else {
              setEditor(e);
            }
          },
        );
      }
    },
    [editor, project_id, config],
  );

  useEffect(() => {
    if (editor && !initialized) {
      editor.onInitialized(() => {
        setInitialized(true);
        onReady?.(editor);
      });
    }
  }, [editor, onReady, initialized]);

  useEffect(() => {
    try {
      editor?.remove();
    } catch (e) {
      // editor is already removed
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        height: "100%",
      }}
      ref={editorRef}
      id={`designHuddle-${project_id}`}
      data-testid={`designHuddle-${project_id}`}
    />
  );
};
