import { useAuth0 } from "@auth0/auth0-react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { OrgUser } from "../../server/supabase/hooks/types";
import { accessTokensAtom } from "./store";
import { useGetDesignHuddleAccessToken } from "./use-design-huddle-access-token";

/**
 * this hook provides the user with user-level access to design huddle,
 * and access to either a specific project, or all their organizations projects
 * this should generally be used at the page level, and not within components
 * It stores the access token in a jotai atom, to avoid re-fetching
 */
export function useDesignHuddle(projects: string[]): boolean {
  const { user } = useAuth0<OrgUser>();
  const getAccessToken = useGetDesignHuddleAccessToken();
  const [ready, setReady] = useState(false);
  const [accessTokens, setAccessTokens] = useAtom(accessTokensAtom);

  const key = projects.join(",");
  const accessToken = accessTokens[key];

  console.log({
    user,
    projects,
    accessTokens,
    accessToken,
    ready,
  });

  useEffect(() => {
    if (ready) return;

    if (accessToken !== undefined) {
      void accessToken
        ?.then((token) => {
          DSHDLib.configure({
            access_token: token,
            visitor: false,
          });
          setReady(true);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      if (!user) return;

      setAccessTokens((prev) => ({
        ...prev,
        [key]: getAccessToken(projects),
      }));
    }
  }, [accessToken, getAccessToken, key, projects, ready, setAccessTokens, user]);

  return ready;
}
