import { Box } from "@mui/material";
import { FC } from "react";
import { AssetCard } from "./asset-card";
import { Asset } from "./types";

export const AssetGrid: FC<{ assets: Asset[] }> = ({ assets }) => {
  return (
    <Box display="grid" gap={4} gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))">
      {assets.map((asset) => (
        <AssetCard key={asset.asset_id} asset={asset} data-testid="asset" />
      ))}
    </Box>
  );
};
