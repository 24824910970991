import {
  BlurLinearOutlined,
  Business,
  ChevronLeft,
  Home,
  Language,
  LineStyleRounded,
  PermMedia,
  PieChartOutlineRounded,
  Send,
  Timeline,
} from "@mui/icons-material";
import { Box, Button, IconButton, Link, Stack, Typography } from "@mui/material";
import { useAtomValue } from "jotai";
import { first, isArray } from "lodash-es";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { usePermissions } from "../../../../server/auth/use-permissions";
import { useFlag } from "../../../../server/optimizely";
import { useSupabase } from "../../../../server/supabase/hooks";
import { Calendar } from "../../../../styles/icons/calendar";
import { makeDefaultFilters } from "../../../../utils/default-filters";
import { UUID } from "../../../../utils/uuid";
import { useAnalytics } from "../../../analytics/analytics";
import { SupabaseBrandKitService } from "../../../brand-kit/server/brand-kit-service";
import { useAnalyticsPerms } from "../../../employees/hooks/use-analytics";
import { BetaBadge } from "../../../generic/components/beta/beta-badge";
import { SupabaseAccountService } from "../../../generic/server/supabase-account-service";
import { PipeIFrame } from "../../../pipe/components/pipe-iframe";
import { Login } from "../../../pipe/types";
import { ReleaseNotes } from "../../../release-notes/release-notes";
import { layoutAtom } from "../store";
import { ThemeAwareDivider } from "./divider";
import { Item } from "./item";
import { Programs } from "./programs";
import { Section } from "./section";
import { SettingsMenu } from "./settings-menu";

export const useIsActive = (): ((path: string, startsWith?: boolean) => () => boolean) => {
  const location = useLocation();
  return useCallback(
    (path, startsWith) => () =>
      startsWith ? location.pathname.startsWith(path) : location.pathname === path,
    [location.pathname],
  );
};

export interface NavigationProps {
  onClose?: () => void;
}
export const Navigation: FC<NavigationProps> = ({ onClose }) => {
  const [logins, setLogins] = useState<{ [k: string]: Login }>({});
  const { t } = useTranslation();
  const [multiFormat] = useFlag("multi_format");
  const [assetLibrary] = useFlag("asset_library");
  const navigate = useNavigate();
  const { gaEvent } = useAnalytics();

  const isActive = useIsActive();
  const perms = usePermissions();

  const analyticsPerms = useAnalyticsPerms();
  const showRecipientActivityPage = perms.super_admin;

  const filters = makeDefaultFilters().toString();

  const [state, setState] = useState<{
    account_id?: UUID;
    loading: boolean;
    name: string;
    logo?: string;
  }>({
    loading: true,
    name: "",
  });

  useSupabase(async ({ supabase, account_id }) => {
    const [{ data: accountData }, { data: logoData }] = await Promise.all([
      new SupabaseAccountService(supabase).get(account_id),
      new SupabaseBrandKitService(supabase).getDefaultLogo(account_id),
    ]);

    const logo = isArray(logoData?.brand_logo)
      ? first(logoData?.brand_logo ?? [])
      : logoData?.brand_logo;

    setState({
      account_id,
      loading: false,
      name: accountData?.name ?? "",
      logo: logo?.src,
    });
  }, []);

  const layout = useAtomValue(layoutAtom);

  return (
    <Stack sx={{ height: "100%" }}>
      <Stack alignItems="center" direction="row" spacing={1} p={2}>
        {layout === "sequence" && onClose && (
          <IconButton data-analytics-id="sidebar-close" onClick={onClose}>
            <ChevronLeft />
          </IconButton>
        )}
        <Box
          sx={{
            height: 35,
            width: 35,
            flexBasis: 40,
            flexShrink: 0,
          }}
        >
          {state.logo ? (
            <img
              alt="logo"
              src={state.logo}
              style={{
                objectFit: "contain",
                height: "100%",
                width: "100%",
              }}
            />
          ) : (
            <Business />
          )}
        </Box>
        <Typography color="inherit" whiteSpace={"nowrap"} flexGrow={1} overflow="hidden">
          {state.name}
        </Typography>
        {layout === "default" && <SettingsMenu size="small" logins={logins} />}
      </Stack>
      <Stack
        component="nav"
        spacing={2}
        sx={{
          flexGrow: 1,
          px: 2,
        }}
      >
        {multiFormat ? (
          <Button
            data-analytics-id="sidebar-create-content"
            fullWidth
            onClick={() => {
              gaEvent("multiformat_button", { action: "click", value: "Create Content" });
              navigate({ pathname: "/create-content" });
            }}
            variant="contained"
          >
            {t("Create Content")}
          </Button>
        ) : (
          <Button
            data-analytics-id="sidebar-create-moment"
            fullWidth
            href={"/create-moment"}
            variant="contained"
          >
            {t("Create New Moment")}
          </Button>
        )}

        <ReleaseNotes />
        <Section>
          <>
            <Item
              title={t("Home")}
              icon={<Home />}
              onClick={{ type: "href", value: "/moments/dashboard" }}
              active={isActive("/moments/dashboard")}
            />
            {assetLibrary && (
              <Item
                title={t("Asset Library")}
                icon={<PermMedia />}
                onClick={{ type: "href", value: "/asset-library" }}
                active={isActive("/asset-library")}
              />
            )}
            <Item
              title={t("Discover")}
              icon={<Language />}
              onClick={{ type: "href", value: "/discover" }}
              active={isActive("/discover", true)}
            />
            <Programs />
            {perms?.super_admin && (
              <Item
                title={t("Sent Moments")}
                icon={<Send />}
                onClick={{ type: "href", value: "/moments/history" }}
                active={isActive("/moments/history")}
              />
            )}
            {["both", "analytics-only"].includes(analyticsPerms) && (
              <BetaBadge overlap="circular" sx={{ "& .MuiBadge-badge": { top: "16px" } }}>
                <Item
                  title={t("Analytics")}
                  icon={<PieChartOutlineRounded />}
                  onClick={{ type: "href", value: "/analytics" }}
                  active={isActive("/analytics")}
                />
              </BetaBadge>
            )}

            {showRecipientActivityPage === true && (
              <Item
                title={t("Recipient Activity")}
                icon={<Timeline />}
                onClick={{ type: "href", value: "/recipient-activity" }}
                active={isActive("/recipient-activity", true)}
              />
            )}
          </>
        </Section>
        <ThemeAwareDivider />
        <Section title={t("Planner")}>
          <>
            <Item
              title={t("Calendar")}
              icon={<Calendar />}
              onClick={{ type: "href", value: `/calendar?${filters}` }}
              active={isActive("/calendar")}
            />
            <Item
              title={t("Timeline")}
              icon={<BlurLinearOutlined />}
              onClick={{ type: "href", value: `/timeline/start_date?${filters}` }}
              active={isActive("/timeline", true)}
            />
            <Item
              title={t("Orchestrator")}
              icon={<LineStyleRounded />}
              onClick={{ type: "href", value: `/programs/orchestrator?${filters}` }}
              active={isActive("/programs/orchestrator")}
            />
          </>
        </Section>
        <ThemeAwareDivider />
        <Box sx={{ p: 3 }}>
          <Link
            variant="caption"
            href="https://help.changeengine.com"
            target="_blank"
            rel="noreferrer"
            color="text.sidebar"
            underline="always"
          >
            {t("Need help?")}
          </Link>
        </Box>
        {state.name && state.account_id ? (
          <PipeIFrame setLogins={setLogins} name={state.name} account_id={state.account_id} />
        ) : null}
      </Stack>
    </Stack>
  );
};
