import { faker } from "@faker-js/faker";
import { CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { redirect } from "react-router-dom";
import { assetFactory } from "../../__factories__/asset-library/asset";
import { AssetCount } from "../../modules/asset-library/asset-count";
import { AssetGrid } from "../../modules/asset-library/asset-grid";
import { Asset } from "../../modules/asset-library/types";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { useApiLoader } from "../../server/api/hooks/use-api-loader";
import { useFlag } from "../../server/optimizely";

const mockAssets = Promise.resolve(assetFactory.buildList(faker.number.int({ min: 1, max: 100 })));

const AssetLibraryPage: FC<{ assets?: Promise<Asset[]> }> = ({ assets }) => {
  const { t } = useTranslation();
  const [enabled, , ready] = useFlag("asset_library");

  const { data, loading, error } = useApiLoader(() => {
    return assets ?? mockAssets;
  }, [assets]);

  if (ready && !enabled) {
    redirect("/404");
  }

  if (!ready || loading) return <CircularProgress />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Page title={t("Your Asset Library")}>
      <PageHeader>
        <PageHeading heading={t("Your Asset Library")} />
      </PageHeader>
      <PageContent maxWidth="xl">
        <Stack spacing={4} pt={2}>
          <AssetCount assets={data} />
          <AssetGrid assets={data} />
        </Stack>
      </PageContent>
    </Page>
  );
};

export default AssetLibraryPage;
