import { Download } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDialog } from "../../utils/hooks/use-dialog";
import { useAnalytics } from "../analytics/analytics";
import { DesignHuddleEditor } from "../design-huddle/design-huddle-editor";
import { DesignHuddleExportModal } from "../design-huddle/design-huddle-export-modal";
import { RenameFileModal } from "./rename-file-modal";

export const EditContent: FC<{
  projectId: string;
  page: number;
  onReady: () => void;
}> = ({ projectId, page, onReady }) => {
  const { t } = useTranslation();
  const { gaEvent } = useAnalytics();

  const [searchParams] = useSearchParams();
  const title = searchParams.get("title") ?? "";
  const [filename, setFilename] = useState(title);
  const renameFileModal = useDialog();
  const exportModal = useDialog();

  useEffect(() => {
    setFilename(filename);
  }, [filename]);

  const [key, setKey] = useState(0);
  const handleOnSavePressed = (pickedFilename: string): void => {
    setFilename(pickedFilename);
    setKey((prev) => prev + 1);
  };

  return (
    <>
      <Stack direction="row" p={2}>
        <Box flexGrow={1} sx={{ ml: 2 }}>
          <Typography variant="h5" color="text.secondary">
            {filename}{" "}
            <Button
              data-analytics-id="edit-content-edit-name"
              onClick={() => renameFileModal.handleOpen()}
            >
              {t("Edit name")}
            </Button>
          </Typography>
        </Box>

        <Stack direction="row" gap={2}>
          <Button
            data-analytics-id="edit-content-export-image"
            variant="contained"
            size="small"
            startIcon={<Download />}
            onClick={() => {
              gaEvent("multiformat_button", {
                action: "click",
                value: "Export Image in Editor",
              });
              exportModal.handleOpen();
            }}
          >
            {t("Export")}
          </Button>
          <DesignHuddleExportModal
            key={key}
            projectId={projectId}
            page={page}
            dialog={exportModal}
            filename={filename}
          />
          <RenameFileModal
            dialog={renameFileModal}
            filename={filename}
            onSavePressed={handleOnSavePressed}
          />
        </Stack>
      </Stack>
      {projectId !== "dummy" && (
      <DesignHuddleEditor
        project_id={projectId}
        page_number={page.toString()}
        hide_right_panel
        onReady={onReady}
      />
      )}
    </>
  );
};
