import { Card, CardContent, CardMedia, CardProps, Chip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FC } from "react";
import { Asset } from "./types";

export const AssetCard: FC<{ asset: Asset } & CardProps> = ({ asset, ...props }) => {
  return (
    <Card {...props}>
      <CardMedia src={asset.thumbnail_url} component="img" />
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="subtitle1" noWrap textOverflow="ellipsis">
            {asset.title}
          </Typography>
          <Stack direction="row">
            <Chip label={asset.content_type} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
